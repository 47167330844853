.app__newsletter {
  background: var(--color-black);
  max-width: 1120px;
  margin: auto;
}

.app__newsletter-heading {
  text-align: center;
}

.app__newsletter-input {
  flex-direction: row;
  margin-top: 3rem;
}

.app__newsletter-input input {
  width: 620px;
  border: 1px solid var(--color-golden);
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--font-base);
  color: var(--color-white);

  margin-right: 2rem;
  padding: 0.75rem 1rem;
  background: var(--color-black);
}

.app__newsletter-input button {
  width: max-content;
}

.container {
  width: 100%;
}

form {
  margin: 2rem 0;
}
form:after {
  content: "";
  display: table;
  clear: both;
}
form .field {
  position: relative;
  display: block;
  border: 1px solid #fff;
  float: left;
  width: 100%;
}

form .error-label {
  position: absolute;
  color: red;
  font-family: sans-serif;
  left: 35px;
  bottom: 10px;
  font-size: 12px;
}
form .field.tnb {
  border-top: 0;
}

form .field.tnb:last-of-type {
  margin-bottom: 25px;
}

form .field:nth-child(1),
form .field:nth-child(2) {
  border-bottom: none;
}

form .field.half {
  width: 50%;
}
form .field.last {
  border-left: 0;
}
form .field input,
form .field textarea,
select {
  display: block;
  width: 100%;
  height: 80px;
  padding: 0 35px;
  font-size: 1rem;
  border: 0;
  color: #212121;
  outline: 0;
}
form .field textarea {
  padding-top: 35px;
  min-height: 150px;
  resize: vertical;
}

input[type="text"] {
  background-color: black;
  color: white !important;
}

input[type="text"]::placeholder {
  color: white;
}

input[type="email"] {
  background-color: black;
  color: white !important;
}

input[type="email"]::placeholder {
  color: white;
}

textarea {
  background-color: black;
  color: white !important;
}

textarea::placeholder {
  color: white;
}

/* select style */
select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  color: #fff;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex !important;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem 3rem 2.5rem;
  color: white;
  transition: 0.25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: var(--color-golden);
}

.custom__button {
  margin-top: 20px !important;
}

.thank-you-msg {
  color: #ffeb39;
  padding: 2rem 5rem;
  font-size: 1.5rem;
  border: 1px solid #ffeb39;
  font-family: var(--font-alt);
  text-align: center;
}

@media screen and (min-width: 2000px) {
  .app__newsletter-input input {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding: 1.7rem;
  }
}

@media screen and (max-width: 990px) {
  .app__newsletter-input {
    flex-direction: column;
    width: 100%;
  }

  .app__newsletter-input input {
    margin: 0 0 2rem 0;
    width: 100%;
  }

  .container {
    padding: 1.7rem;
  }
}

@media screen and (max-width: 650px) {
  .app__newsletter {
    padding: 2rem 0;
    border: none;
  }

  .thank-you-msg {
    color: #ffeb39;
    padding: 1rem 3rem;
    margin: 0 1rem;
    font-size: 1rem;
    border: 1px solid #ffeb39;
    font-family: var(--font-alt);
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .app__newsletter-heading h1 {
    font-size: 32px;
    line-height: 50px;
  }

  form .field.half {
    width: 100%;
  }

  form .field.last {
    border: 1px solid white;
  }

  .container {
    padding: 1.7rem;
  }
  form .field input,
  form .field textarea {
    font-size: 13px;
  }
}
