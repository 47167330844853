.app__feature-t {
  background: var(--color-black);
  z-index: 10;
  position: relative;
}

.app__third-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.app__feature-third {
  flex: 1 1;
  width: 100%;
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
  margin-bottom: auto;
  padding-top: 100px;
  right: 190px;
  position: relative;
}

.app__feature-textbg {
  font-family: var(--font-base);
  color: var(--color-black);
  font-size: 80px;
  font-weight: 800;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  line-height: 0.8;
  position: absolute;
  left: -80px;
}

@media screen and (min-width: 2000px) {
  .app__chef-sign img {
    width: 370px;
  }
}

@media screen and (min-width: 1920px) {
  .app__feature-third{
    right: 500px;
  }

  .text-third {
    padding: 0 7rem;
  }

}

@media screen and (min-width: 1024px) {
  .app__feature-third{
    padding-top: 90px;
  }

  .third-video {
    width: 450px;
    padding-top: 4rem;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS styles for tablets */
  .app__feature-textbg {
    display: none;
  }

  .app__feature-third {
    padding-top: 0;
    right: 0;
    padding: 1rem;
    bottom: 265px;
    min-width: 0;
  }
}

@media screen and (max-width: 450px) {
  .app__feature-t {
    position: relative;
    display: flex !important;
    width: 100%;
    height: 700px; /* set fixed width and height */
    z-index: 5;
}

  .app__feature-textbg {
    display: none;
  }

  .app__wrapper_img img {
    min-width: auto !important;
  }

  .app__feature {
    height: 0;
  }

  .app__feature-third {
    position: absolute; /* position the text element absolutely */
    bottom: 0;
    min-width: 0;
    right: 0;
    padding: 3rem;
}
}
