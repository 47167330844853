section {
  position: relative;
  background-color: var(--color-black);
  
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 900;
  text-transform: uppercase;
  font-family: var(--font-base);
  font-size: 80px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  color: var(--color-black);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.parallax span {
  display: block;
  margin-right: 30px;
}
