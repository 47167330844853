.app__feature-s {
  background: var(--color-black);
  z-index: 10;
  position: relative;
}

.app__wrapper-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__second-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.app__feature-second {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
  margin-bottom: auto;
  padding-top: 200px;
  left: 100px;
  position: relative;
}

.app__second-textbg {
  font-family: var(--font-base);
  color: var(--color-black);
  font-size: 80px;
  font-weight: 800;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  line-height: 0.8;
  position: absolute;
  right: -180px;
}

@media screen and (min-width: 2000px) {
  .app__chef-sign img {
    width: 370px;
  }
}

@media screen and (min-width: 1920px) {
  .app__feature-second{
    left: 200px;
  }

  .text-second {
    padding: 0 7rem;
  }

}

@media screen and (min-width: 1440px) {
  .app__feature-second{
    left: 160px;
  }

}

@media screen and (min-width: 1024px) {
  .second__mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS styles for tablets */
  .app__second-textbg {
    display: none;
  }
  .app__feature-second {
    padding-top: 0;
    left: 0;
    padding: 1rem;
    bottom: 265px;
  }

  .app__wrapper-s {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .app__feature-s {
    position: relative;
    display: flex !important;
    width: 100%;
    height: 720px; /* set fixed width and height */
    overflow: unset !important;

}

  .app__second-textbg {
    display: none;
  }

  .app__wrapper-s {
    flex-direction: column;
    min-height: 0;
  }

  .app__wrapper_img img {
    min-width: auto !important;
  }

  .app__feature {
    height: 0;
  }

  .app__feature-second {
    position: absolute; /* position the text element absolutely */
    bottom: -120px;
    left: 0;
    padding: 3rem;
}
  .second__desktop {
    display: none !important;
  }
}
