.app__footer {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: var(--color-black);
  padding-top: 0;
}

.app__footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5rem;
  padding: 0 2rem;
  background: var(--color-golden);
}

.app__footer-links_contact {
  flex: 1;
  margin: 2rem 2rem;
  text-align: left;
}

.app__footer-headtext {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 64px;
  line-height: 41.6px;
  margin-bottom: 1rem;
}

.footer__copyright {
  margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
  .app__footer-headtext {
    font-size: 51px;
    line-height: 61.6px;
  }
}

@media screen and (max-width: 1150px) {
  .app__footer-links {
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .app__footer-links_contact {
    margin: 2rem 0;
    width: 100%;
    padding: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app__footer-links_contact {
    text-align: center;
    padding: 1rem;
  }

  .app__footer-headtext {
    font-size: 40px;
    margin-bottom: 2rem;
}
}

@media screen and (max-width: 650px) {
  .app__footer {
    padding: 0 0 2rem 0;
  }
}

@media screen and (max-width: 450px) {
  .app__footer-links_contact {
    text-align: center;
    padding: 1rem;
  }

  .app__footer-headtext {
    font-size: 40px;
    margin-bottom: 2rem;
}
}
