.swiper__container {
  z-index: 100;
  position: relative;
  top: 40rem;
  overflow: hidden;
}

.swiper__container-mobile {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}


.slider {
  align-items: center;
  max-width: 1450px;
  position: relative;
  height: 1200px;
  display: flex;
  justify-content: center;
}



#highlight-slide {
  pointer-events: none;
  height: 660px;
  width: 350px;
  border-radius: 35px;
  position: absolute;
  border: 8px solid #333343;
  z-index: 100;
  top: 0;
  background-color: #333343;
}

#highlight-slide:before {
  content: "";
  position: absolute;
  top: 27%;
  transform: translateY(-50%);
  left: -13px;
  width: 12px;
  height: 40px;
  border-radius: 4px;
  background-color: #333343;
}


.button {
  content: "";
  position: absolute;
  top: 20%;
  left: -4%;
  transform: translateY(-50%);
  width: 12px;
  height: 40px;
  border-radius: 4px;
  background-color: #333343;
  z-index: 100;
}

#highlight-slide:after {
  content: "";
  position: absolute;
  top: 24%;
  transform: translateY(-50%);
  right: -14px;
  width: 12px;
  height: 80px;
  border-radius: 4px;
  background-color: #333343;
}

.mobile-bar {
  position: absolute;
  top: 1px;
  z-index: 100;
  scale: 0.734;
}

.mobile-bar img {
  border-radius: 40px 40px 0 0;
}

.swiper-bar {
  position: absolute;
  top: 42%;
  z-index: 100;
  scale: .7;
}

@media only screen and (min-width: 600px) {
  #highlight-slide {
    /* left: 300px; */
  }
}

#highlight-slide video {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  opacity: 0;
}


#highlight-slide.visible video {
  opacity: 1;
  transition: opacity 1s;
}

.swiper-wrapper {
  top: 8%;
}

/* semi circle */
.semi-circle {
  height: 75rem;
  background-color: var(--color-golden);
  border-radius: 40rem 40rem 0 0;
}

/* base css */
.section__padding-about {
  /* padding: 4rem 0; */
}
.app__aboutus {
  position: relative;
  background-color: var(--color-black);
  /* padding-top: 10rem !important; */
}

.app__aboutus-overlay {
  width: 100%;
  position: absolute;
  flex-direction: column;
  z-index: 10;
}

.app__aboutus-title {
  position: relative;
  top: 27rem;
  z-index: 1;
  text-align: center;
  padding: 0 20rem;
}

.app__aboutus-overlay img {
  width: 100%;
  z-index: 0;
}

.app__aboutus-content {
  width: 100%;
  z-index: 10;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-slide video {
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
}

@media screen and (min-width: 2000px) {
  .app__aboutus-content_phone img {
    height: 1110px;
  }

  .app__aboutus-content_live1 p,
  .app__aboutus-content_live2 p {
    margin: 4rem 0;
  }
  .circle_mobile {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .semi-circle{
    /* min-width: 1920px !important; */
    height: 110rem;
    border-radius: 60rem 60rem 0 0 !important;
  }

  .app__aboutus-title{
    top: 35rem;
    padding: 0 30rem;
  }

  .button{
    left: -4%;
  }

}

@media screen and (min-width: 1440px) {
  .semi-circle{
    /* min-width: 1440px; */
    border-radius: 45rem 45rem 0 0;
  }
  .button{
    left: -4%;
  }

  .app__aboutus{
    padding-top: 0 !important;
  }

}


@media screen and (max-width: 1150px) {
  .circle_mobile {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .semi-circle{
    /* min-width: 1024px; */
  }
  .button{
    left: -4%;
  }

  .app__aboutus{
    padding-top: 0 !important;
  }

  .app__aboutus-title{
    padding: 0 12rem;
  }

}

@media screen and (max-width: 900px) {
  .app__aboutus-content {
    flex-direction: column;
  }

  .app__aboutus-content_phone {
    margin: 4rem 0;
  }

  .circle_mobile {
    display: none;
  }

  .semi-circle {
    /* min-width: 48rem; */
    /* margin-top: -1rem; */
    height: 80rem;
  }

  .app__aboutus-title {
    padding: 0 5rem;
    top: 27rem;
  }

  .button {
    left: -4%;
}

.app__aboutus,.section__padding-about{
  padding: 0 !important;
}

.swiper__container{
  top: 38rem;
}
}

@media screen and (max-width: 650px) {
  .app__aboutus-overlay img {
    width: auto;
  }

  .app__aboutus-content {
    height: 390px;
  }

  .app__aboutus-content_phone img {
    height: 590px;
  }

  .app__aboutus-content_phone {
    top: 70px;
  }
  .app__aboutus-title {
    padding: 0 36rem;
  }

  .app__aboutus-overlay {
    top: -260px;
  }

  .app__aboutus-content_live1 {
    display: none;
  }

  .app__aboutus-content_live2 {
    display: none;
  }

  .circle_mobile {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .swiper__container-mobile {
    z-index: 100;
    position: relative;
    top: 5rem;
    overflow: hidden;
    display: block;
  }

  .swiper-slide img {
    border-radius: 35px;
  }

  .slider__gif {
    align-items: center;
    max-width: 1450px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  #highlight-slide-gif{
      pointer-events: none;
      height: 560px;
      width: 300px;
      border-radius: 35px;
      position: absolute;
      border: 8px solid #333343;
      background-color: #333343;
      z-index: 100;
      top: 0;
  }
  

  #highlight-slide-gif img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    opacity: 0;
  }
  
  #highlight-slide-gif.visible img {
    opacity: 1;
    transition: opacity 1s;
  }

  .app__aboutus {
    padding-top: 250px !important;
  }

  .semi-circle {
    width: 100%;
    margin-top: 7rem;
    height: 50rem;
  }

  .app__aboutus-title {
    padding: 0 25px;
    top: 23rem;
  }

  .swiper-slide video {
    width: 100px !important;
    height: 200px !important;
  }

  #highlight-slide {
    height: 300px;
    border-radius: 25px;
    width: 175px;
  }

  #highlight-slide video {
    border-radius: 25px;
  }

  .swiper__container {
    /* overflow: hidden;
    top: 10rem; */
    display: none;
  }

  .swiper-wrapper {
    top: 0;
  }


#highlight-slide-gif:before {
  content: "";
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  left: -13px;
  width: 12px;
  height: 40px;
  border-radius: 4px;
  background-color: #333343;
}

#highlight-slide-gif:after {
  content: "";
  position: absolute;
  top: 24%;
  transform: translateY(-50%);
  right: -14px;
  width: 12px;
  height: 80px;
  border-radius: 4px;
  background-color: #333343;
}

  .button {
    top: 29%;
    left: -4.5%;
}

  .mobile-bar img {
    border-radius: 60px 60px 0 0;
  }

  .mobile-bar {
    position: absolute;
    top: -2px;
    z-index: 100;
    scale: 0.63;
  }

  .swiper-bar {
    scale: 0.6;
    top: 75%;
  }

  .slider {
    height: 480px;
  }
}
