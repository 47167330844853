.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  padding:2rem 6rem;
  z-index: 100;
}

.flex__mobile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

.app__navbar-logo img {
  width: 116px;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
}

.app__navbar-links li:hover {
  color: var(--color-grey);
}


.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: .5s ease;
  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-white);
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.overlay__logo {
  color: var(--color-white);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
  margin-top: 6rem;
  width: 80%;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-white);
  font-size: 20px;
  text-align: left;
  font-family: var(--font-alt);
  border-bottom: 1px solid #fff;
  padding-bottom:1rem;
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-golden);
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 991px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: var(--color-black); */
    padding:.55rem 1rem;
    background: transparent;
    z-index: 100;
  }

  .app__navbar-logo {
    display: none;
  }

  .app__navbar-logo-small img {
    width: 40px;
  }

}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-logo img {
    width: 110px;
  }
}