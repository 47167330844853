.section__padding {
  padding: 4rem 6rem;
}

.section__padding-footer {
  padding: 4rem 0;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  z-index: 10;
  margin-bottom: auto;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-head {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
  position: relative;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
  z-index: 1;
  min-width: 540px;
}

.app__wrapper_img span {
  /* overflow-x: hidden; */
}

.app__wrapper_img video {
  width: 100%;
}

.app__wrapper_img-head .slanted-img {
  width: 260px;
  position: absolute;
  top: -570px;
  left: -120%;
  -ms-transform: rotate(10deg); /* IE 9 */
  transform: rotate(10deg);
  z-index: 5;
  display: block;
}

.slanted-img:nth-child(2) {
  margin-top: -5px;
}

.app__wrapper_img-head img {
  width: 100%;
  display: block;
}

.custom__button {
  background-color: var(--color-golden);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 900;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 21px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 16px;
}

.p__cormorant-black {
  font-family: var(--font-base);
  color: var(--color-black);
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 16px;
}

.p__cormorant-white {
  font-family: var(--font-base);
  color: var(--color-white);
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.p__cormorant-smallwhite {
  font-family: var(--font-base);
  color: var(--color-white);
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 14px;
  font-weight: 900;
}

.p__cormorant-small {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 900;
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 14px;
}

.p__cormorant-smallblack {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 900;
  font-feature-settings: "tnum" on, "lnum" on;
  font-size: 14px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  font-size: 21.33px;
}

.p__opensans-footer {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
}

.headtext__cormorant {
  font-family: var(--font-alt);
  color: var(--color-black);
  font-size: 76px;
}

.headtext__cormorant-white {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-size: 64px;
  line-height: 0.8;
}

.spoon__img {
  width: 200px;
  max-height: 400px;
  padding: 0 1rem;
}

@media screen and (min-width: 1920px) {
  .app__wrapper {
    min-height: 0;
  }
  .app__wrapper_info {
    padding: 6rem 6rem;
  }

  .app__wrapper_img-head .slanted-img {
    left: 0;
  }

  .headtext__cormorant {
    font-size: 96px;
  }
  .p__cormorant-smallblack {
    font-size: 17px;
  }
  .p__cormorant-black {
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .app__wrapper_img-head .slanted-img {
    left: -200px;
  }

  .app__wrapper {
    min-height: 0;
  }

}

@media screen and (min-width: 1200px) {
  .app__wrapper_img img {
    display: none;
  }

  .p__cormorant-black {
    padding: 0 40px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .app__wrapper_img-head .slanted-img {
    left: -385px;
    overflow: hidden;
  }

  .app__wrapper {
    min-height: 0;
  }
  .app__wrapper_img img {
    display: none;
  }

  .section__padding {
    padding: 1rem 0 1rem 3rem;
  }


}

/* @media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS styles for tablets */
    .app__wrapper {
    min-height: 0;
  }
  .section__padding {
    padding: 4rem;
  }
  .headtext__cormorant {
    font-size: 60px;
    line-height: 80px;
  }

  .app__wrapper_img-head .slanted-img {
    width: 240px;
    overflow: hidden;
    left: -460px;
  }

  .app__wrapper_img video {
    display: none;
  }

  .app__wrapper_img-reverse {
    justify-content: center;
  }

  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_info {
    padding: 4rem;
  }

  .app__wrapper_img-head img {
    opacity: 0.5;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }

  .app__wrapper_img-head .slanted-img {
    width: 170px;
    overflow: hidden;
    display: block;
    position: absolute;
    top: -570px;
    left: -10%;
    transform: rotate(10deg);
    z-index: 5;
  }
}

@media screen and (max-width: 450px) {
  /* CSS styles for phone */
  .section__padding{
    padding: 2rem;
  }
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 14px;
    line-height: 1.7;
  }

  .p__cormorant-black {
    font-size: 14px;
  }
  .p__cormorant-white {
    font-size: 13px;
  }
  .headtext__cormorant {
    font-size: 37px;
    line-height: 70px;
  }

  .headtext__cormorant-white {
    font-size: 32px;
  }
  .app__wrapper_img-head img {
    opacity: 0.5;
  }

  .app__wrapper_img img{
    width: 100%;
  }

  .app__wrapper_img {
    width: 100%; /* fix the width of image container */
    display: flex;
    justify-content: center;
    align-items: center;
}

  .app__wrapper_img-head .slanted-img {
    width: 170px;
    overflow: hidden;
    display: block;
    position: absolute;
    top: -570px;
    left: 0;
    transform: rotate(10deg);
    z-index: 5;
  }
  .app__wrapper {
    flex-direction: column;
    min-height: 0;
  }


  .p__opensans-footer {
    font-size: 25px;
  }

  .marquee__bg {
    width: 300%;
    top: -330px;
  }

  .app__wrapper_img video {
    display: none;
  }
}
