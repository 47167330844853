.app__wrapper-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 60vh;
}

.section__padding-head {
  padding: 4rem 6rem 3rem 13rem;
}

.app__header {
  background-color: var(--color-black);
  /* padding: 4rem 10rem !important; */
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 900;
  text-transform: capitalize;
  line-height: 70px;
  font-size: 96px;
}

.app__header-img img {
  width: 80%;
}

@media screen and (min-width: 1920px) {
  .app__wrapper-head{
    min-height: 0;
  }

}
@media screen and (min-width: 1440px) {
  .app__wrapper-head{
    min-height: 0;
  }
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }
}

@media screen and (min-width: 1024px) {
  .section__padding-head{
    padding: 5rem 4rem 0rem 10rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS styles for tablets */
  .app__header-h1 {
    font-size: 76px;
  }

  .section__padding-head {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .slanted-img {
    opacity: .5;
  }
  .app__wrapper_info {
    position: relative;
    padding: 0;
  }

  .app__header-h1 {
    font-size: 39px;
    line-height: 35px;
  }

  .app__wrapper-head {
    display: block;
    padding: 30px;
    /* padding-top: 130px; */
  }

  .app__wrapper-head {
    min-height: unset;
  }
}

@media screen and (max-width: 450px) {

}
